import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menu', 'arrow']

  connect() {
    this.menuVisible = false
    document.addEventListener('click', this.closeMenuIfClickedOutside.bind(this))
  }

  disconnect() {
    document.removeEventListener('click', this.closeMenuIfClickedOutside.bind(this))
  }

  toggle(event) {
    event.stopPropagation()

    this.menuVisible = !this.menuVisible
    this.menuTarget.classList.toggle('hidden', !this.menuVisible)
    this.arrowTarget.classList.toggle('rotate-180', this.menuVisible)
  }

  closeMenuIfClickedOutside(event) {
    if (this.menuVisible && !this.element.contains(event.target)) {
      this.menuVisible = false
      this.menuTarget.classList.add('hidden')
      this.arrowTarget.classList.remove('rotate-180')
    }
  }
}
