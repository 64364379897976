import { SortableController } from './sortable_controller.js'

export default class extends SortableController {
  get selectors() {
    return {
      container: '.js-item-sortable',
      positionInput: '.js-item-order',
      upButton: '.js-up-button',
      downButton: '.js-down-button'
    }
  }
}
